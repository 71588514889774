import React from 'react'
import styled from 'styled-components'
import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import { Video as VideoItem } from '../../graphql/contentfulTypes'
import SectionTitle from './SectionTitle'
import Video from '../Video'
import Slider from '../FramerSlider'

type Props = {
  items: VideoItem[]
  title?: string
  className?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('VideosList__')

const Wrapper = styled.div`
  padding-bottom: 100px;
  padding-left: 20px;
  color: ${({ theme }) => theme.colors.brand.default};

  .${cssPrefix} {
    &slider {
      margin-top: 65px;
    }

    &slide {
      width: 300px;
      height: 250px;
      margin-right: 20px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.max}) {
    .${cssPrefix} {
      &slide-video {
        &-title {
          font-size: 17px;
        }

        &-description {
          font-size: 12px;
          bottom: 20px;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) and (${breakpoints.tabletLandscape.max}) {
    .${cssPrefix} {
      &slide-video {
        &-title {
          font-size: 24px;
        }

        &-description {
          font-size: 14px;
          bottom: 28px;
        }
      }
    }
  }

  @media screen and (${breakpoints.tabletLandscape.min}) {
    padding-left: 25px;

    .${cssPrefix} {
      &slide {
        margin-right: 25px;
        width: 400px;
        height: 260px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding-left: 90px;

    .${cssPrefix} {
      &slider-nav.next {
        right: 65px;
      }

      &slide {
        margin-right: 45px;
        width: 640px;
        height: 415px;
      }
    }
  }
`

const VideosList = ({ items, title, className = '' }: Props) => {
  if (!items || items?.length === 0) return null

  return (
    <Wrapper className={`${cssPrefix} ${className}`}>
      {title && <SectionTitle text={title} />}
      <Slider itemsCount={items?.length} className={toPrefix('slider')}>
        {items?.map((item) => (
          <div key={item.contentful_id} className={toPrefix('slide')}>
            <Video
              src={item.video.file.url}
              poster={item.videoPoster.file.url}
              title={item.title}
              description={item.videoDescription}
              className={toPrefix('slide-video')}
              preload="none"
            />
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default VideosList
